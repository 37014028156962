import GATSBY_COMPILED_MDX from "/opt/build/repo/content/posts/printnightmare-vulnerability.mdx";
import * as React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import {MDXProvider} from "@mdx-js/react";
import {Link} from "gatsby";
import Navbar from "../components/navbar";
import FootBar from "../components/footer";
import "../components/layout.scss";
import "../css/_section-audit.scss";
import Seo from "../components/seo";
import BlogForm from "../components/forms/blogForm";
import CamForm from "../components/forms/camForm";
const shortcodes = {
  Link
};
const breadData = [{
  id: 1,
  ink: "/",
  label: "HOME"
}, {
  id: 3,
  ink: "/blogs/",
  label: "Blogs"
}];
function PageTemplate({data, children}) {
  const TemplateForm = data.mdx.frontmatter.template === "blog" ? React.createElement(BlogForm) : data.mdx.frontmatter.template === "campaign" ? React.createElement(CamForm) : null;
  return React.createElement(React.Fragment, null, React.createElement(Seo, {
    title: data.mdx.frontmatter.title
  }), React.createElement(Navbar, {
    siteTitle: `Title`
  }), React.createElement("div", {
    className: "container-fluid p-0"
  }, React.createElement("main", null, React.createElement("section", {
    className: "section-post-single mt-5"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "row"
  }, React.createElement("div", {
    className: "col-sm-12 col-md-6 col-lg-6 col-xl-8 pr-5"
  }, React.createElement("h2", null, data.mdx.frontmatter.title), React.createElement("h3", {
    className: "title-sec-h3"
  }, "By ", data.mdx.frontmatter.author, ", ", data.mdx.frontmatter.jobtitle), React.createElement("h4", {
    className: "pb-5"
  }, data.mdx.frontmatter.series), React.createElement(MDXProvider, {
    components: shortcodes
  }, children)), React.createElement("div", {
    className: "col-sm-12 col-md-6 col-lg-5 col-xl-4 "
  }, React.createElement("div", {
    className: "box sticky-md-top sticky-lg-top sticky-xl-top "
  }, TemplateForm))))), React.createElement("div", {
    className: "breadcrumbs"
  }, React.createElement("nav", {
    className: "container-fluid p-sides-med"
  }, React.createElement("ul", null, breadData.map(breadcrumb => React.createElement("li", {
    key: breadcrumb.id
  }, React.createElement(Link, {
    to: breadcrumb.ink
  }, breadcrumb.label))), React.createElement("li", null, React.createElement(Link, {
    to: data.mdx.frontmatter.blog
  }, data.mdx.frontmatter.series)), React.createElement("li", null, React.createElement(Link, {
    to: data.mdx.frontmatter.slug
  }, data.mdx.frontmatter.title)))))), React.createElement(FootBar, {
    siteTitle: `Title`
  })));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
MDXProvider.propTypes = {
  children: PropTypes.node.isRequired
};
export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMM D, YYYY")
        author
        jobtitle
        series
        excerpt
        blog
        template
      }
    }
  }
`;
